import React from "react"
// import { navigate } from 'gatsby'
import Layout from "../components/Layout.js"
import notFoundImage from "../images/not-found-image.svg"

export default () => {
  // navigate('/')

  return (
    <Layout>
      <main className="max-w-4xl flex-grow mx-auto flex flex-col justify-around">
        <img className="p-4" src={notFoundImage} alt="Page not found" />
      </main>
    </Layout>
  )
}
